/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const $ = require("jquery");
const Tether = require('tether')
window.$ = $
window.jQuery = $
window.Tether = Tether
require("jquery-colorbox/example3/colorbox.css")
require("jquery-colorbox")
require("jquery.marquee")
require("flexslider")
require("animate.css")
require("bootstrap")

exports.onInitialClientRender = () => {
  hc1Functions()
}

exports.onClientEntry = () => {
  hc1Functions()
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname.includes('blog')) {
    const slug = location.pathname.replace('/blog/', '')
    if (!slug.includes('page/') || !slug.includes('tag/') || !slug.includes('category/')) {
      if (!slug.includes('blog')) {
        let newSlug = slug.endsWith('/') ? slug.slice(0, -1) : slug;
        $.post('https://strapi.hc1.com/post-views/record-view', { postSlug: newSlug })
      }
    }
  }

  hc1Functions()
}

function hc1Functions() {
  $('head').append('<script>\n' +
      '  (function () {\n' +
      '    var zi = document.createElement(\'script\');\n' +
      '    zi.type = \'text/javascript\';\n' +
      '    zi.async = true;\n' +
      '    zi.referrerPolicy = \'unsafe-url\';\n' +
      '    zi.src = \'https://ws.zoominfo.com/pixel/6165c4e90b0f5a00156fa330\';\n' +
      '    var s = document.getElementsByTagName(\'script\')[0];\n' +
      '    s.parentNode.insertBefore(zi, s);\n' +
      '  })();\n' +
      '  </script>');


  $('.st-btn.copy-link').on('click', function(e) {
    const currentURL = window.location.href // returns the absolute URL of a page
    async function copyToClip() {
      await navigator.clipboard.writeText(currentURL);
    }
    copyToClip();

    $('#link-copied-text').addClass('show');

    setTimeout(function(){
      $('#link-copied-text').removeClass('show');
    }, 5500);
  });

  $('.sbi_load_btn').on('click', function(e) {
    e.preventDefault()
    let items = $('.sbi_item')
    let theIndex = parseInt($(this).attr('data-index'), 10)
    $(this).attr('data-index', theIndex + 1)
    items.each(function(i) {
      if (i <= ((theIndex * 4) + 3)) $(this).show();
    })
    if ((theIndex * 4) + 4 >= items.length) $('.sbi_load_btn').hide()
  });

  $('.scroll-to').on('click', function(e) {
    e.preventDefault()
    const scrollName = $(this).attr('href').replace('#', '')
    const scrollTo = $(`[name=${scrollName}]`)
    $('html, body').animate({
      scrollTop: scrollTo.offset().top
    }, 1000)
  })

  const screensize = $(window).width()

  //Add Hover effect to menus
  $('ul.nav li.dropdown').hover(function() {
    $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn()
  }, function() {
    $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut()
  })

  $('.mega-first-dropdown-menu').hide()

  $('ul.nav li.mega-first-level').hover(function() {
    $(this).find('.mega-first-dropdown-menu').stop(true, true).delay(100).fadeIn()
  }, function() {
    $(this).find('.mega-first-dropdown-menu').stop(true, true).delay(100).fadeOut()
  })

  // restore the default behavior of bootstrap dropdown toggle elements
  $('.dropdown-toggle').on('click', function() {
    if (this.href) {
      window.location = this.href
    }
  })

  $('#comparisonScreenSlideshow').carousel(5000)
  $('#testimonialSlideshow').carousel(30000)

  $('.banner .banner-bg-image').css('opacity', 1)
  $('.overlay .blue-overlay').css('opacity', .67)

  let caseStudyMods = document.querySelectorAll('.resource-template-default .case-study-module');
  $(caseStudyMods).first().addClass('case-study-first');

  function bannersizer() {
    const dh = $('.banner-bg-image').outerHeight() //div height
    const dth = $('.banner-text h3').outerHeight() //get height of h3
    const ticker = $('.logo-ticker-bar').outerHeight()
    // const screensize = $(window).width()
    const btxt = dth + 82 + 77 // bannertextheight = height of h3 + height of h2 (not resized) + height of cta buttons block
    const imght = dh - btxt - 80 //banner height - btxt from above - 60px of padding for nav gives remaining height left for additional img

    $('.overlay').css('height', dh)
    $('.overlay:after').css('height', dh)
    $('.banner-inside').css('height', dh)
    $('.banner-inside.marquis').css('height', dh - ticker)

    // set max-height for smaller screens
    if (imght < (dh / 2)) {
      $('.additional-banner-image-wrap').css('height', imght)
      $('.additional-banner-image').css('height', imght)
    }

    if ($('.vid-wrap').hasClass('video-overlay')) {
      $('.banner-inside').css('position', 'relative').addClass('move-up')
    }

    if ($('.banner-inside').hasClass('marquis')) {
      if (screensize < 768) {
        $('.banner-inside').css('height', dh - 60)
      } else if (screensize > 768 && screensize < 1199) {
        $('.banner-inside').css('height', dh - 90)
      } else if (screensize > 1199) {
        $('.banner-inside').css('height', dh - 110)
      }
    }

    if ($('.subhero').hasClass('sub134')) {
      if (screensize < 768) {
        $('.banner-inside').css('height', dh - 35, 'border')
      } else {
        $('.banner-inside').css('height', dh - 50)
      }
    } else if ($('.subhero').hasClass('sub2')) {
      if (screensize < 768) {
        $('.banner-inside').css('height', dh - 35)
      } else {
        $('.banner-inside').css('height', dh - 40)
      }
    }

    if ($('.subsearch').hasClass('sub56')) {
      $('.banner-inside').css('height', dh - 30)
    } else if ($('.subsearch').hasClass('sub9')) {
      if (screensize < 767) {
        $('.banner-inside').css('height', dh - 30)
      } else if ((screensize > 768) && (screensize < 1199)) {
        $('.banner-inside').css('height', dh - 130)
      } else if (screensize > 1199) {
        $('.banner-inside').css('height', dh - 210)
      }
    }


    if ($('.subform').hasClass('sub78')) {
      if (screensize < 768) {
        $('.banner-inside').css('height', dh - 35)
      } else {
        $('.banner-inside').css('height', dh - 40)
      }
    }

    if ($('body').hasClass('single-event')) {
      if (screensize < 991) {
        $('.banner-inside').css('height', dh)
      } else {
        $('.banner-inside').css('height', dh - 40)
      }
    }

    if ($('.resource-form').hasClass('form_with_content_block__form-wrap')) {
      if (screensize < 991) {
        $('.banner-inside').css('height', dh)
      } else {
        $('.banner-inside').css('height', dh - 40)
      }
    }

    if ($('.form_with_content_block__form-wrap').hasClass('form-last')) {
      if (screensize < 991) {
        $('.banner-inside').css('height', dh)
      } else {
        $('.banner-inside').css('height', dh - 40)
      }
    }

    const takeaways = $('.key-takeaways').outerHeight()
    $('.case-study-first').css('paddingTop', takeaways)

  }

  $(window).on('load resize', () => {
    bannersizer()

    $('.rotating-content').flexslider({
      animation: 'slide',
      controlNav: false,
      directionNav: false,
      animationLoop: true,
      animationSpeed: 1500,
      slideshow: true,
      move: 1,
      itemWidth: '100%',
      slideshowSpeed: 3000
    })

    // RELATED CONTENT SLIDER @ DIFFERENT BREAKPOINTS
    if(screensize > 1200) {
      $('#relatedContentSlideshow').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#relatedContentSlideshow').width() / 4,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize >= 768 && screensize <= 1200) {
      $('#relatedContentSlideshow').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#relatedContentSlideshow').width() / 3,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize <= 767) {
      $('#relatedContentSlideshow').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#relatedContentSlideshow').width()/1,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    // AWARDS SLIDER @ DIFFERENT BREAKPOINTS
    if(screensize > 1200) {
      $('#awardsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#awardsSlider').width() / 4,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize >= 768 && screensize <= 1200) {
      $('#awardsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#awardsSlider').width() / 3,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize <= 767) {
      $('#awardsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#awardsSlider').width()/1,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    // NEWS SLIDER @ DIFFERENT BREAKPOINTS
    if(screensize > 1200) {
      $('#newsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#newsSlider').width() / 4,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize >= 768 && screensize <= 1200) {
      $('#newsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#newsSlider').width() / 3,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize <= 767) {
      $('#newsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#newsSlider').width()/1,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

  })

  $(window).scroll(function() {
    $('.rotating-content').flexslider({
      animation: 'slide',
      controlNav: false,
      directionNav: false,
      animationLoop: true,
      animationSpeed: 1500,
      slideshow: true,
      move: 1,
      itemWidth: '100%',
      slideshowSpeed: 3000
    })

    // RELATED CONTENT SLIDER @ DIFFERENT BREAKPOINTS
    if(screensize > 1200) {
      $('#relatedContentSlideshow').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#relatedContentSlideshow').width() / 4,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize >= 768 && screensize <= 1200) {
      $('#relatedContentSlideshow').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#relatedContentSlideshow').width() / 3,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize <= 767) {
      $('#relatedContentSlideshow').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#relatedContentSlideshow').width()/1,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }


    // AWARDS SLIDER @ DIFFERENT BREAKPOINTS
    if(screensize > 1200) {
      $('#awardsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#awardsSlider').width() / 4,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize >= 768 && screensize <= 1200) {
      $('#awardsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#awardsSlider').width() / 3,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize <= 767) {
      $('#awardsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#awardsSlider').width()/1,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    // NEWS SLIDER @ DIFFERENT BREAKPOINTS
    if(screensize > 1200) {
      $('#newsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#newsSlider').width() / 4,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize >= 768 && screensize <= 1200) {
      $('#newsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#newsSlider').width() / 3,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    if(screensize <= 767) {
      $('#newsSlider').flexslider({
        animation: 'slide',
        controlNav: false,
        directionNav: true,
        animationLoop: true,
        animationSpeed: 1500,
        slideshowSpeed: 3000,
        itemWidth: $('#newsSlider').width()/1,
        move: 1,
        prevText: '',
        nextText: ''
      })
    }

    $(function() {

      // commmenting this out bcause i don't think it's used anymore
      // show hide subnav depending on scroll direction
      // let position = $(window).scrollTop()

      $(window).scroll(function() {
        let scroll = $(window).scrollTop()

        if (scroll === 0) {
          $('.header-wrap').removeClass('normal')
          $('ul.navbar-nav').removeClass('normal')
          $('.mobile-nav').removeClass('normal')
          $('.mobile-nav.active').addClass('normal')
        } else if (scroll < 50) {
          $('.header-wrap').removeClass('normal')
          $('ul.navbar-nav').removeClass('normal')
          $('.mobile-nav').removeClass('normal')
          $('.mobile-nav.active').addClass('normal')
        } else {
          $('.header-wrap').removeClass('scrolled').addClass('normal')
          $('ul.navbar-nav').removeClass('scrolled').addClass('normal')
          $('.mobile-nav').removeClass('scrolled').addClass('normal')
          $('.mobile-nav.active').removeClass('scrolled').removeClass('normal')
        }
      })

    })

  })

  $('a.internal-link').on('click', function(e) {
    e.preventDefault()
    const target = $(this).attr('href')
    const $target = $(target)
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, 900, 'swing', function() {
      window.location.hash = target
    })
  })

  let dtNavHeight = $('.header-wrap').outerHeight();

  if(screensize >= 992) {
    $('.banner-inside').css('paddingTop', dtNavHeight);
    $('.subscribe').css('paddingTop', dtNavHeight);
  }

  if (screensize <= 767) {
    let tabs = $('.platform-module .nav-tabs li')
    let length = tabs.length
    // console.log(length)

    // loop thru the list items
    tabs.each(function(index) {
      let item = $(this)
      let itemHeight = item.outerHeight() + 15 // adding 15px to top of item height for spacing
      // console.log(itemHeight)

      let ulHeight = (length * itemHeight) + 20 // adding 20px to height to add extra space to top of ul
      // console.log(ulHeight)
      $('.platform-module .nav-tabs').css({ 'height': ulHeight })

      setTimeout(function() {
        $(item).css({ 'bottom': (itemHeight * index) + 20 }) // starting bottom at 20px to space out ul and buckets
      }, 800 * index)
    })

    $('.list-item-inner').hide()
    $('.list-item-inner.first').show()

    $('.list-img-inner').removeClass('hovered')
    $('.list-img-inner.first').addClass('hovered')

    $('.list-img').on('click touchstart', function() {
      $('.list-item-inner').hide()
      $('.list-img-inner').removeClass('hovered')
      let targetClass = '.list-item-inner' + '.' + $(this).attr('data-target')
      $(targetClass).fadeToggle(100)
    })
  } else {
    $('.list-item-inner').hide()
    $('.list-item-inner.first').show()

    $('.list-img-inner').removeClass('hovered')
    $('.list-img-inner.first').addClass('hovered')

    $('.list-img').hover(function() {
      $('.list-item-inner').hide()
      $('.list-img-inner').removeClass('hovered')
      let targetClass = '.list-item-inner' + '.' + $(this).attr('data-target')
      $(targetClass).show();
    })
  }

  $('.logo-ticker').marquee({
    direction: 'left',
    duration: '50000',
    startVisible: 'true'
  })

  if ($('.banner-video').length > 0) {
    // Resive video
    scaleVideoContainer()
  }

  initBannerVideoSize('.video-bg video')

  $(window).on('resize', function() {
    scaleVideoContainer()
    scaleBannerVideoSize('.video-bg video')
  })

  // Trigger the wistia video on gravity form submission.
  $('#gform_submit_button_29').on('click', function() {
    $('.wistia_click_to_play').trigger('click')
  })


  /** Reusable Functions **/
  /********************************************************************/

  function scaleVideoContainer() {

    const height = $(window).height()
    const unitHeight = parseInt(height) + 'px'
    $('.homepage-hero-module').css('height', unitHeight)

  }

  function initBannerVideoSize(element) {

    $(element).each(function() {
      $(this).data('height', $(this).height())
      $(this).data('width', $(this).width())
    })

    scaleBannerVideoSize(element)

  }

  $(".job-board-button").css({"opacity": "0", "pointer-events": "none"});

  $(window).on('load', function () {
    if (window.location.href.indexOf("/careers?gh_jid") > -1) {
      $(".job-board-button").css({"opacity": "1", "pointer-events": "auto"});
    }
  })

  $(window).on('scroll', function() {

    //PLATFORM MODULE
    // find out how many list items there are in the ul
    let tabs = $('.platform-module .nav-tabs li')
    let length = tabs.length
    // console.log(length)

    // loop thru the list items
    tabs.each(function(index) {
      let item = $(this)
      let itemHeight = item.outerHeight() + 15 // adding 15px to top of item height for spacing
      // console.log(itemHeight)

      let ulHeight = (length * itemHeight) + 20 // adding 20px to height to add extra space to top of ul
      // console.log(ulHeight)
      $('.platform-module .nav-tabs').css({ 'height': ulHeight })

      setTimeout(function() {
        $(item).css({ 'bottom': (itemHeight * index) + 20 }) // starting bottom at 20px to space out ul and buckets
      }, 2000 * index)
    })

  })

}

function scaleBannerVideoSize(element) {

  let windowWidth = $(window).width(),
    windowHeight = $(window).height(),
    videoWidth,
    videoHeight

  $(element).each(function() {
    let videoAspectRatio = $(this).data('height') / $(this).data('width'),
      windowAspectRatio = windowHeight / windowWidth

    if (videoAspectRatio > windowAspectRatio) {
      videoWidth = windowWidth
      $(this).css({ 'width': videoWidth })

    } else {

      videoHeight = windowHeight
      videoWidth = videoHeight / videoAspectRatio
      $(this).css({ 'width': videoWidth })

    }
  })

}
